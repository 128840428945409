import React, { Component } from 'react'
import { Card, CardDeck, Col, Container, Image, Row  } from 'react-bootstrap';
import '../Consultoria/consultoria.css'
import img1 from '../../Imagenes/img1.png'
import img2 from '../../Imagenes/img2.png'
import img3 from '../../Imagenes/img3.png'
import img4 from '../../Imagenes/img4.png'
import img5 from '../../Imagenes/img5.png'


class Financiamiento extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
      window.scroll(0,0);

        return (
        
               <>

                

                <div class="row row-cols-1 row-cols-md-5 w-100  m-0 h-100 ">
       
                <div class="col h-100  w-100 " >
                    <div class="card TarjetaCard ">
                    <Image className='d-block ml-auto mr-auto w-50 mt-2'src={img1} />
                        <div class="card-body text-dark texto1">
                            <h3 class="card-title">Procesos Criticos</h3>
                            <p class="card-text">Analizamos cual es la parte critica y riesgos para cada proceso en tu negocio.</p>
                        </div>
                    </div>
                </div>

                <div class="col h-100 w-100" >
                    <div class="card TarjetaCard ">
                    <Image className='d-block ml-auto mr-auto  w-50 mt-2'src={img5}  />
                        <div class="card-body text-dark texto1">                     
                            <h3 class="card-title">Puestos de Trabajo</h3>
                            <p class="card-text">Fortalecemos cada puesto de trabajo asegurando el resultado esperado por la empresa.</p>
                        </div>
                    </div>
                </div>

                <div class="col h-100 w-100" >
                    <div class="card TarjetaCard ">
                    <Image className='d-block ml-auto mr-auto  w-50 mt-2'src={img4}  />
                        <div class="card-body text-dark texto1">                          
                            <h3 class="card-title">Indicadores KPI"S	</h3>
                            <p class="card-text">Establecemos indicadores para que puedas seguir la evolución de cada uno de tus procesos, sobre todo los mas críticos.</p>
                        </div>
                    </div>
                </div>

                <div class="col  h-100 w-100" >
                    <div class="card TarjetaCard">
                    <Image className=' d-block ml-auto mr-auto w-50 mt-2'src={img3}  />
                        <div class="card-body text-dark texto1">                      
                            <h3 class="card-title">Plan de acción</h3>
                            <p class="card-text">Nos adelantamos a los riesgos que puedan afectar la continuidad del negocio o la calidad del servicio entregado.</p>
                        </div>
                    </div>
                </div>

                <div class="col  h-100 w-100" >
                    <div class="card TarjetaCard">
                      <Image className=' d-block ml-auto mr-auto w-50 mt-2'src={img2}  />
                        <div class="card-body text-dark texto1">                      
                            <h3 class="card-title">Mejora Continua</h3>
                            <p class="card-text"> Siempre conectados con las oportunidades de mejora, potenciando el desarrollo y crecimiento de la empresa.</p>
                        </div>
                    </div>
                </div>
        </div>


                    <h2 className='text-center texto2'>	ASESORAMOS A TU ORGANIZACIÓN DE LA FORMA MAS EFICIENTE	</h2>
                    <p className='text-center texto2'>Impulsamos el crecimiento de las empresas por medio del desarrollo de soluciones y herramientas permitiendo una mayor eficiencia en sus procesos.</p>

    </>

);
    }
}
 
export default Financiamiento;