import React, { Component } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import reunion from '../../Imagenes/reunion.webp'

import './consultoria.css'

class Consultoria extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {


        return (<div>
                     <h1 className='amzu animate__animated  animate__fadeInDown'>Bienvenidos a AMZU</h1>
                    <p className='text-center texto2'>Nuestro Objetivo principal es apoyar a la organización mediante la solución de problemas, el descubrimiento y evaluación de nuevas oportunidades y la puesta en practica de los cambios, donde el único riesgo es el beneficio.</p>
                    <hr className='hr mb-5' />
                </div>

        );
    }
}

export default Consultoria;