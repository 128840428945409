import React, { Component } from 'react';
import { Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import './menu.css'
import logo from '../Imagenes/logo2.png'


class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    redirectS=()=>{  
      console.log('Lluege hasta aqui perra');
      this.props.history.push('/SobreNosotros');
    }


    render() { 
        return ( <Navbar  className='menu  fixed-top' expand="lg">
                <Image className='logo'src={logo} />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav " className='collapseMenu'>
          <Nav className="ml-auto textoMenu animate__slideInUp animate__animated">

            <Nav.Link  ><Link className='textInicio' to='/' > Inicio </Link></Nav.Link>

            <Nav.Link ><Link  className='textInicio' to='/SobreNosotros' onClick={this.redirectS} > Sobre Nosotros </Link> </Nav.Link>

            <Nav.Link ><Link  className='textInicio' to='/NuestrosPrincipios' > Servicios </Link> </Nav.Link>

            <Nav.Link ><Link  className='textInicio' to='/Contacto' > Contáctanos </Link></Nav.Link>

          </Nav>
          
        </Navbar.Collapse>
      </Navbar> );
    }
}
 
export default withRouter(Menu);
