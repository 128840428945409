import React, { Component } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';


class TarjetaEquipo extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
        <Container className='mt-5'>
     
        </Container>
      );
    }
}
 
export default TarjetaEquipo;