import React, { Component } from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap';
import './SobreNosotros.css'
import TarjetaEquipo from './TarjetaEquipo/tarjetaEquipo';
import principios from '../Imagenes/principios2.png'
import Testimonio from '../Inicio/Testimonio/testimonio';
import { Link, withRouter } from 'react-router-dom';
import alianza from '../Imagenes/alianza.png'
import alianza1 from '../Imagenes/alianza1.png'



class SobreNosotros extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        window.scroll(0, 0);

        return (<div>
            <section className="d-flex align-items-center justify-content-center mr-auto text-dark  inicio">
                <h2 className='textoNosotros  animate__animated text-white animate__fadeInDown'>Nosotros</h2>
            </section>

            <section class=" bg-white w-100 pt-5 pb-5 text-dark text-center">

                <h2 className='TituloNoaotros '>Somos profesionales en desarrollo organizacional</h2>
                <p className='textoNosotros1'>Trabajamos con equipos para apoyarlos en su crecimiento y desarrollo para que puedan asegurar su éxito y sustentabilidad a la vez que alcanzan la excelencia y un buen clima laboral.</p>
                <hr className='hr mb-3' mt-3 />
                <Image className='w-50 ' src={principios} />

            </section>

           

            <section class=" SERVICIO text-white w-100 p-4 d-flex align-items-center ">
                <div className='p-3'>
                    <h2 className='textoInicio2'>¿Necesitas mas información?</h2>
                    <p className='textoInicio2'>Estamos aquí para ayudarte con tu negocio.</p>
                </div>
                <Link className='boton btn btn-info ml-auto' to='/Contacto' > Contáctanos </Link>
            </section>

      
         

            <section className='p-3'>
           
                  
                    <Row className=''> 
                        
                        <Col xs={6} md={4} className='mt-5 ml-3  d-flex align-items-center justify-content-center '> 
                            <Image className=' w-100 ' src={alianza}  />
                        
                        </Col>


                        <Col xs={12} md={7} className='ml-3 mb-2 mt-5 lineaVertical ' >
                        <h2 className='textoAlianza1 text-dark'>Alianza Estrategica</h2>
                        <p className='textoAlianza'>Servicios de Gestión Integral  y Auditorias de Inspección Técnica de
                                        Calidad,Seguridad Laboral, Medio Ambiente. Alimentación y Tecnologías de la Información</p>
                 
                       </Col>
                        
                    </Row>

                    <Row className=''> 
                        
                        <Col xs={12} md={7} className='ml-3 mb-2 mt-5 lineaVertical2 ' >
                            <p className='textoAlianza ml-5 mt-5'>servicios de consultoría Tributaria y Contable</p>
                        </Col>
                        
                        <Col xs={6} md={4} className='mt-5 ml-3  d-flex align-items-center justify-content-center '> 
                            <Image className=' w-100 ' src={alianza1}  />
                        
                        </Col>
                        
                    </Row>
         </section>

            

        </div>);
    }
}

export default withRouter(SobreNosotros);

  //*  <h2 className=" textoServicio text-dark">Nuestros Clientes</h2>
  <section class=" bg-white pt-5 pb-5">
  <Testimonio />
</section> //