import React, { Component } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap';
import './EstiloPrincipios.css'
import s1 from '../Imagenes/s1.jpg'
import s2 from '../Imagenes/s2.jpg'
import s3 from '../Imagenes/s3.jpg'
import s4 from '../Imagenes/s4.jpg'


class NuestrosPrincipios extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        window.scroll(0,0);
        return (
            
    <div> <section className="d-flex align-items-center justify-content-center mr-auto  w-100 inicio">
        <h1 className='textoNosotros text-white animate__animated animate__fadeInDown'>Servicios</h1>
    </section> 

    <section class=" bg-white w-100 pt-5 pb-5  d-flex align-items-center justify-content-center">
            

                <div class="row row-cols-1 row-cols-md-2 w-100 tarjet m-0 pt-5 pb-2 text-center container ">

                    

                <div class="col  h-100 w-100e mb-5 " >
                    <Image className='w-100 ' src={s1} />
                  
                        <div class="card-body texto1 text-dark">
                            <h3 class="card-title">Ingeniería de Procesos</h3>
                            <p class="card-text">Analizamos los puntos críticos de tu negocio para poder optimizar de la mejor manera los recursos de la empresa logrando eficiencia y rentabilidad.</p>
                     
                    </div>
                </div>

                
                <div class="col  h-100 w-100  mb-5 " >
                <Image className='w-100 ' src={s2} />
                  
                        <div class="card-body text-dark texto1">
                            <h3 class="card-title">Implementación de Sistemas de Gestión ISO</h3>
                            <p class="card-text">Asesoramos y acompañamos a nuestros clientes en el proceso de estructuración e implementación de sistemas de gestión.</p>
                        </div>
                    
                </div>

                <div class="col h-100 w-100  mb-5 " >
                <Image className='w-100 ' src={s3} />
          
                        <div class="card-body text-dark texto1">
                            <h3 class="card-title">Consultoría Empresarial</h3>
                            <p class="card-text">Analizamos tu negocio y transformamos la información en indicadores que aportan a la toma de decisiones del área estratégica, complementamos la ingeniera de procesos con el análisis financiero. </p>
                        </div>
                 
                </div>

                <div class="col h-100 w-100  mb-5 " >
                <Image className='w-100 ' src={s4} />
              
                        <div class="card-body text-dark texto1">
                            <h3 class="card-title">Outsourcing Administrativo</h3>
                            <p class="card-text">Brindamos servicios profesionales de externalización de procesos administrativos.</p>
                        </div>
            
                </div>

        </div>
        </section>


        </div> );
    }
}
 
export default NuestrosPrincipios;