import React, { Component } from 'react'
import { Button, Form } from 'react-bootstrap';
import './contacto.css'

class Contacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: '',
      empresa: '',
      cargo: '',
      correo: '',
      mensaje: '',
      respuesta: ''
    }
  }
  handleNombre = (e) => {
    this.setState({
      nombre: e.target.value
    })
  }
  handleCorreo = (e) => {
    this.setState({
      correo: e.target.value
    })
  }
  handleEmpresa = (e) => {
    this.setState({
      empresa: e.target.value
    })
  }
  handleCargo = (e) => {
    this.setState({
      cargo: e.target.value
    })
  }
  handleMensaje = (e) => {
    this.setState({
      mensaje: e.target.value
    })
  }

  onClickContact = async (e) => {
    e.preventDefault();

    var datos = new FormData();
    datos.append('Nombre', this.state.nombre);
    datos.append('Correo', this.state.correo);
    datos.append('Cargo', this.state.cargo);
    datos.append('Empresa', this.state.empresa);
    datos.append('Mensaje', this.state.mensaje);

    const config = {
      method: 'POST',
      body: datos
    }
    const urlCont = 'https://amzu.cl/requestPhp/Enviar.php';
    fetch(urlCont, config)
      .then(res => res.json())
      .then(res =>
        {
          this.setState({
            respuesta: res
          })
        }
      )
  }

  render() {


    return (
      <main> <section className="d-flex align-items-center justify-content-center mr-auto   inicio">
        <h1 className='TextoContacto  text-white animate__animated  animate__fadeInDown'>Contáctanos</h1>
      </section>

        <section className="formulario bg-white w-100 p-5">
          <Form className='w-75 d-block ml-auto mr-auto'>
            <Form.Group >
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" className='imput' onChange={this.handleNombre} />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Empresa</Form.Label>
              <Form.Control type="text" className='imput' onChange={this.handleEmpresa} />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Cargo</Form.Label>
              <Form.Control type="text" className='imput' onChange={this.handleCargo} />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Correo Electronico</Form.Label>
              <Form.Control type="email" className='imput' onChange={this.handleCorreo} />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Mensajes</Form.Label>
              <Form.Control as="textarea" rows={3} className='imput' onChange={this.handleMensaje} />
            </Form.Group>

          </Form>
          <Button className='d-block ml-auto mr-auto' onClick={this.onClickContact} variant="info">Enviar</Button>
        </section>

        {this.state.respuesta!=='' &&  <div className='text-center bg-info '><p >Enviado</p></div> }

      </main>);
  }
}

export default Contacto;