import React, { Component } from 'react'
import { Card, CardColumns, CardDeck, Col, Container, Image, Row } from 'react-bootstrap';
import cliente1 from '../../Imagenes/cliente1.jpg';
import cliente2 from '../../Imagenes/cliente2.jpg';
import cliente3 from '../../Imagenes/cliente3.jpg';
import cliente4 from '../../Imagenes/cliente4.jpg';
import cliente5 from '../../Imagenes/cliente5.jpg';
import cliente6 from '../../Imagenes/cliente6.jpg';
import '../inicio.css'

class Testimonio extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (<main>

<div class="row row-cols-1 row-cols-md-3 w-100 mb-3 m-0 text-center ">
                <div class="col mb-4 h-100" >
                    <div class="card h-100 ">
                        <div class="card-body ">
                               <Image className='imgCliente' src={cliente1} />
                        </div>    
                    </div>
                </div>

                <div class="col mb-4 h-100" >
                    <div class="card h-100 ">
                        <div class="card-body ">
                        <Image className='imgCliente' src={cliente2}   />
                        </div>    
                    </div>
                </div>

                <div class="col mb-4 h-100" >
                    <div class="card h-100 ">
                        <div class="card-body ">
                        <Image className='imgCliente' src={cliente3} />
                        </div>    
                    </div>
                </div>

                <div class="col mb-4 h-100" >
                    <div class="card h-100 ">
                        <div class="card-body ">
                        <Image className='imgCliente' src={cliente4} />
                        </div>    
                    </div>
                </div>

                <div class="col mb-4 h-100" >
                    <div class="card h-100 ">
                        <div class="card-body ">
                        <Image className='imgCliente' src={cliente5} />
                        </div>    
                    </div>
                </div>

                <div class="col mb-4 h-100" >
                    <div class="card h-100 ">
                        <div class="card-body ">
                        <Image className='imgCliente' src={cliente6} />
                        </div>    
                    </div>
                </div>
</div>
    </main>);
  }
}

export default Testimonio;