import React, { Component } from 'react';
import Consultoria from './Consultoria/consultoria';
import Financiamiento from './Financiamiento/financiamiento';
import './inicio.css'
import Testimonio from './Testimonio/testimonio';
import metodologia from '../Imagenes/metodologiaPNG.png'
import { Button, Col, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';


class Inicio extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        window.scroll(0,0);


        return (
    <main class='overDiv'>

            <section className="d-flex align-items-center justify-content-center mr-auto text-dark  inicio">
                <h2 className='textoInicio animate__fadeInDown animate__animated '>TE AYUDAMOS A RENTABILIZAR TU NEGOCIO</h2></section> 

            <section class="inicio2 text-dark w-100 pt-5 pb-5">
             <Consultoria/> 
        
            </section>
            <div className='  pb-5'>
                <Financiamiento/>
            </div>
            <section class=" SERVICIO text-white w-100 p-4 d-flex align-items-center "> 
           
            <div className='p-3'>
             <h2 className='textoInicio2'>¿Necesitas mas información?</h2>
             <p className='textoInicio2'>Estamos aquí para ayudarte con tu negocio.</p>
             </div>
         
             <Link  className='boton btn btn-info ml-auto'  to='/Contacto' > Contáctanos </Link>
            
             </section>
           
         <h1 className=" textoServicio text-dark ">Metodología</h1>
            <Row>

            <Col className='  d-flex align-items-center justify-content-center'>
                    <Image className=' imgMetodologia ' src={metodologia}  />
                </Col>
                <Col className='  d-flex align-items-center '> 
                    <p className='text-center text3 '>Impulsamos el crecimiento de las empresas por medio del desarrollo de soluciones, permitiendo una mayor eficiencia en sus procesos.</p>
                </Col>
               
            </Row>
          

           

    </main>
    
    );
    }
}
 
export default Inicio;