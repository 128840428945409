import logo from './logo.svg';
import './App.css';
import { Alert } from 'react-bootstrap';
import {BrowserRouter,Switch,Route, Redirect} from 'react-router-dom';
import Menu from './Componentes/Menu/menu';
import 'bootstrap/dist/css/bootstrap.min.css';
import Inicio from './Componentes/Inicio/inicio';
import Footer from './Componentes/Footer/footer';
import SobreNosotros from './Componentes/SobreNosotros/sobreNosotros';

import 'animate.css'


import Contacto from './Componentes/Contacto/contacto';
import NuestrosPrincipios from './Componentes/NuestrosPrincipios/nuestrosPrincipios';

import {createBrowserHistory} from 'history';

export const history = createBrowserHistory();


function App() {



  
  return (
    <BrowserRouter history={history}>
    <div className='App'>
    <Menu/>
    
                <Switch>
                  <Route exact path='/' component={Inicio} />
                  <Route exact path='/SobreNosotros' component={SobreNosotros} />
                  <Route exact path='/NuestrosPrincipios' component={NuestrosPrincipios} />
                  <Route exact path='/Contacto' component={Contacto} />
                  <Route >
                      <Redirect to='/' />
                  </Route>
                 
                </Switch>


    <Footer/>
    </div>
    </BrowserRouter>
  );
}


export default App;
