import React, { Component } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import './footer.css'
import logo from '../Imagenes/logo1.png'
import { Link } from 'react-router-dom';


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <>

            <div class="  bg-dark footer1">
                <div class="text-white">
                <Row className='m-0 '> 
                    <Col className='p-5'> 
                    <Image className='imgFooter'src={logo} />
                    <p className='textoFooter  mb-0'>TEL : (+56) 9 5902 5442</p>
                    <p className='textoFooter mb-0'>contacto@amzu.cl</p>
                    </Col>
                    <Col className='p-5'>
                    <h2  className='textoFooter2'>Enlaces De Interes</h2>
                    <Link className='textoFooter' to='/' > Inicio </Link>
                    <Link  className='textoFooter' to='/SobreNosotros' > Sobre Nosotros </Link>
                    <Link  className='textoFooter' to='/NuestrosPrincipios' > Servicios </Link>
                    <Link  className='textoFooter' to='/Contacto' > Contáctanos </Link>
                    </Col>
                    <Col  className='p-5'>
                    <h2  className='textoFooter2'>Servicios</h2>
                    <p className='textoFooter  mb-0'>Ingenieria de Procesos</p>
                    <p className='textoFooter mb-0'>Outsourcing Administrativo</p>
                    <p className='textoFooter  mb-0'>Implementacion de Sistemas de Gestion ISO</p>
                    <p className='textoFooter  mb-0'>Consultoria Empresarial</p>
                        
                    </Col>
                </Row>
                </div>
            </div>

            <div class="  footer">
                <div class="text-white text-center ">
                    <p className='p-0 m-0'>Copyright © 2020 AMZU.</p>
                </div>
            </div>
        </>
        
        );
    }
}
 
export default Footer;